import React from "react"
import { graphql } from "gatsby";
import Layout from "../layouts/Layout"
import { BLOCKS, MARKS } from '@contentful/rich-text-types';
import { renderRichText } from "gatsby-source-contentful/rich-text"

import SiteMetadata from "../components/SiteMetadata"

const Privacy = ({ data, location }) => {
  const plainTextContent = data.privacy.description
  const options_plainText = {
    renderNode: {
      [BLOCKS.HEADING_1]: (node, children) => <h1 className="my-8" >{children}</h1>,
      [BLOCKS.PARAGRAPH]: (node, children) => <p className="small text-sm my-4">{children}</p>,
      [BLOCKS.OL_LIST]: (node, children) => <ol className="pl-4 list-decimal">{children}</ol>,
      [BLOCKS.UL_LIST]: (node, children) => <ul className="">{children}</ul>,
      [BLOCKS.LIST_ITEM]: (node, children) => <li className="">{children}</li>
    },
    renderMark: {},
  }

  return (
    <Layout data={data.meta} location={location}>
      <SiteMetadata title="Privacy Policy" description="Privacy Policy | ChonkerKeys" />

      <div className="container py-32 mx-auto">
        <h1 className="text-5xl my-20 text-center">{data.privacy.title}</h1>
        <div className="w-full pb-8 md:pb-0 drop-shadow-sm">
          {plainTextContent && renderRichText(plainTextContent, options_plainText)}
        </div>
      </div>
    </Layout>
  )
}

export default Privacy

export const query = graphql`
  query Privacy {
    meta: site {
      siteMetadata {
        languages {
          defaultLangKey
          langs
        }
      }
    }
    privacy: contentfulPrivacyPolicy {
        title
        description: content {
          raw
        }
    }
  }
`